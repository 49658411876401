<template>
	<BaseLayout>
		<Modal v-if="showDeliveryMap" @close="onCloseDeliveryMap" title="送貨員位置" variant="primary">
			<GoogleMap api-key="AIzaSyBuhtUy0Jy86x5uT3qaPtZLWzfUhNQ34EQ" class="h-screen overflow-hidden" :center="center" :zoom="16">
				<Marker :options="{ position: center, label: '送貨員' }" />
			</GoogleMap>
		</Modal>
		<div v-else-if="order">
			<Popup v-if="showPopup" @close="onClosePopup" :title="showPopup.title ? showPopup.title : $t('cart.deliveryFee')">
				<div v-if="showPopup.type === 'qrCode'" class="pb-5 text-center">
					<qrcode :value="order.customer.id" :options="{ width: 200 }" tag="img"></qrcode>
					<p>{{ $t('orderDetail.orderId') }}: {{ order.referenceNumber }}</p>
				</div>

				<div v-else-if="showPopup.type === 'comment'" class="py-5">
					<input type="text" placeholder="請輸入評語" class="w-64 border-b-2 bg-transparent text-center placeholder-muted" />
				</div>

				<div v-else class="h-48 overflow-y-scroll">
					<div v-for="service in order.surcharges" :key="service.id" class="text-secondary">
						<div>{{ $t('cart.serviceFreeName') }}: {{ service.name[currentLang] }}</div>
					</div>
				</div>
			</Popup>

			<Toast v-if="error" @close="error = null" :class="error.variant">{{ error.message }}</Toast>

			<Toolbar @clickLeft="$router.push('/account/orders')" @clickRight="$router.push('/account/chat')">
				<template v-slot:start>
					<Icon class="h-5 w-5" icon="angleLeft" />
				</template>
				<p>{{ orderStatus(order.status) }}</p>
				<template v-slot:end>{{ $t('orderDetail.contactUs') }} </template>
			</Toolbar>

			<div class="mb-48 p-5">
				<!-- 選擇時間 -->
				<div class="mb-2 flex justify-between">
					<p class="text-xl font-bold">{{ $t('cart.deliveryTime') }}</p>
				</div>
				<div class="mb-5 inline-flex w-full items-center justify-between rounded-xl bg-light-blue p-4 text-primary">
					<!-- {{ order.deliverySetting }} -->

					<div v-if="order.method.type === 'ONSITE'">
						<p>{{ $t('cart.onSite') }}</p>
					</div>
					<div v-else>
						<p class="text-xl font-black">{{ moment(order.deliverySetting?.time).format('YYYY-MM-DD HH:mm') }}</p>
						<p>{{ order.deliverySetting?.address?.addressLine1 }}</p>
						<!-- <p class="text-xl font-black">{{ moment(order.deliverySetting?.time).format('YYYY-MM-DD HH:mm') }}</p> -->
						<!-- <p>{{ order.deliverySetting.address?.addressLine1 }}</p> -->
						<!-- <p v-if="order.shipment.deliveryType !== 'MYJETMALL_BAC_DELIVERY' && order.shipment.address">{{
							order.shipment.address.addressLine
						}}</p> -->
					</div>
				</div>

				<!-- 購物車內容 -->
				<div class="flex w-full flex-col gap-3">
					<p class="text-xl font-bold">MyJetmall</p>

					<div class="mb-5 rounded-xl bg-light-blue">
						<div v-for="item in orderProducts" :key="item.id" class="grid w-auto grid-cols-8 rounded-xl p-3">
							<!-- {{ getProduct(item) }} -->
							<div v-if="getProduct(item).imageUrls[0]" class="col-span-2 h-20 w-20">
								<img class="h-full rounded-xl object-cover" :src="getProduct(item).imageUrls[0]" alt="" loading="lazy" />
							</div>
							<div class="inline-grid gap-2 px-2" :class="getProduct(item).imageUrls[0] ? 'col-span-6' : 'col-span-8'">
								<div class="flex justify-between">
									<div>{{ item.name[currentLang] }}</div>
									<div>
										<p v-if="getVariantById(item.variantId).value.isCustomPrice">{{ $t('cart.quoteSeparately') }}</p>
										<p v-else-if="getVariantById(item.variantId).value.originalPrice[order.method.type] > item.sellingPrice[order.method.type]" class="flex flex-col items-end text-red-500">
											<del class="text-xs text-muted">${{ getVariantById(item.variantId).value.originalPrice[order.method.type] }}</del
											>${{ item.sellingPrice[order.method.type] }}
										</p>
										<p v-else>${{ item.sellingPrice[order.method.type] }}</p>
									</div>
								</div>
								<div class="flex justify-between">
									<div class="flex flex-col text-sm text-secondary">
										<div>{{ item.brandName }}</div>
										<div>{{ item.sku }}</div>
									</div>
									<div class="-mr-3 flex h-8 max-w-max justify-between rounded-full bg-white px-3 text-primary">
										<!-- 購物車數量 -->
										<p class="text-md my-auto">x {{ item.orderedQuantity }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 選擇優惠 -->
				<div class="mb-2 flex justify-between">
					<p class="text-xl font-bold">{{ $t('orderDetail.orderDetail') }}</p>
				</div>
				<template v-for="(discount, index) in order.discounts" :key="index">
					<div v-if="discount.status !== 'CANCELED'" class="mb-2 inline-flex w-full items-center justify-between rounded-xl bg-light-blue p-4">
						<div class="text-md">
							{{ discount.name[currentLang] }}
						</div>
					</div>
				</template>

				<!-- 購物車總計 -->
				<div class="mb-5 flex flex-col gap-2 rounded-xl bg-light-blue p-5">
					<div v-if="order.referenceNumber" class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('orderDetail.orderId') }}</div>
						<div class="col-span-4 text-right">{{ order.referenceNumber }}</div>
					</div>
					<div class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('cart.merchandiseSubtotal') }}</div>
						<div class="col-span-4 text-right">${{ order.productTotal }}</div>
					</div>
					<div v-if="order.discountTotal" class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('cart.discountSubtotal') }}</div>
						<div class="col-span-4 text-right">${{ order.discountTotal }}</div>
					</div>
					<div v-if="order.surchargeTotal" class="grid grid-cols-8">
						<div class="col-span-4" @click="showPopup = !showPopup" @close="onClosePopup">{{ $t('cart.deliveryFee') }}</div>
						<div class="col-span-4 text-right">${{ order.surchargeTotal }}</div>
					</div>
					<div v-if="order.usedPoints" class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('cart.usedPoints') }}</div>
						<div class="col-span-4 text-right">{{ order.usedPoints }}P</div>
					</div>
					<div class="col-span-8 border-b-2 p-1"></div>
					<div class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('cart.totalAmount') }}</div>
						<div class="col-span-4 text-right">${{ order.grandTotal }}</div>
					</div>
					<div class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('cart.estimatedPointsEarning') }}</div>
						<div class="col-span-4 text-right">{{ order.earnedPoints }}P</div>
					</div>
				</div>
				<Button @click="showReceipt">{{ $t('orderDetail.viewReceipt') }}</Button>

				<div v-if="order.customer.billingAddress" class="mt-4 mb-2 flex justify-between">
					<p class="text-xl font-bold">{{ $t('orderDetail.billDetail') }}</p>
				</div>

				<div v-if="order.customer.billingAddress" class="mb-5 flex flex-col gap-2 rounded-xl bg-light-blue p-5">
					<div class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('orderDetail.name') }}</div>
						<div class="col-span-4 text-right">{{ order.customer.billingAddress.firstName + order.customer.billingAddress.lastName }}</div>
					</div>
					<div class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('orderDetail.phone') }}</div>
						<div class="col-span-4 text-right">{{ order.customer.billingAddress.areaCode + order.customer.billingAddress.phoneNumber }}</div>
					</div>
					<div class="grid grid-cols-8">
						<div class="col-span-4">{{ $t('orderDetail.billingAddress') }}</div>
						<div class="col-span-4 text-right">{{ order.customer.billingAddress.addressLine }}</div>
					</div>
				</div>

				<template v-if="order.remark">
					<!-- 輸入備註 -->
					<div class="mb-2 flex justify-between">
						<p class="text-xl font-bold">{{ $t('orderDetail.remark') }}</p>
						<p class="text-muted">{{ $t('orderDetail.optional') }}</p>
					</div>
					<div class="mb-5 rounded-xl bg-light-blue p-4">
						<textarea v-model="order.remark" rows="3" disabled class="h-16 w-full bg-transparent text-xl placeholder-muted" :placeholder="$t('profile.specialRequest')" />
					</div>
				</template>
			</div>
			<Footer>
				<Button v-if="order.status !== 'CLOSED'" class="mb-4" @click="submit(order.status)" :disabled="isLoading" :variant="order.status == 'OPEN' ? 'danger' : 'primary'">{{ orderStatusActions[order.status] }}</Button>
				<Button v-if="order.status === 'DELIVERED'" @click="submit('SHOWQRCODE')">取貨</Button>
			</Footer>
		</div>
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Browser } from '@capacitor/browser'
import { GoogleMap, Marker } from 'vue3-google-map'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { useSharedStore } from '@/store/shared'
import { useSaleStore } from '@/store/sale'
import { useCatalogStore } from '@/store/catalog'
import { useCartStore } from '@/store/cart'

export default {
	components: {
		GoogleMap,
		Marker,
	},

	setup() {
		const route = useRoute()
		const router = useRouter()
		const store = useStore()
		const { currentLang, chatMessage } = storeToRefs(useSharedStore())
		const { getProduct, getVariantById } = useCatalogStore()
		const { sale } = storeToRefs(useSaleStore())
		const { t } = useI18n({ useScope: 'global' })
		const { currentCart } = storeToRefs(useCartStore())

		const id = computed(() => route.params.id)
		const order = ref(null)
		const deliveryType = ref({
			MYJETMALL_BAC_DELIVERY: t('deliveryType.MYJETMALL_BAC_DELIVERY'),
			MYJETMALL_DELIVERY: t('deliveryType.MYJETMALL_DELIVERY'),
			SF_LOCKER_DELIVERY: t('deliveryType.SF_LOCKER_DELIVERY'),
			SF_EXPRESS_DELIVERY: t('deliveryType.SF_EXPRESS_DELIVERY'),
		})

		const orderStatusActions = ref({
			ON_HOLD: t('orderStatusActions.ON_HOLD'),
			DRAFTED: t('orderStatusActions.PENDING_PAYMENT'),
			OPEN: t('orderStatusActions.PENDING'),
			CANCELLED: t('orderStatusActions.CANCELLED'),
			DELIVERED: t('orderStatusActions.DELIVERED'),
			CLOSED: t('orderStatusActions.COMPLETED'),
			PACKED: t('orderStatusActions.PACKED'),
			RECEIVED: t('orderStatusActions.RECEIVED'),
		})

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		const showPopup = ref(false)
		// const showQRCODE = ref(false)
		// const showComment = ref(false)

		const showDeliveryMap = ref(false)
		const center = { lat: 22.2961483, lng: 113.9117966 }

		onMounted(async () => {
			if (!id.value) return router.push('/home')
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				order.value = sale.value
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				error.value = e
				router.push('/home')
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		})
		const orderProducts = computed(() => order.value.products?.filter(i => i.status == 'CLOSED'))

		// function orderProducts(menu) {
		// 	if (!order.value) return []
		// 	return [...order.value.products.filter(i => i.categoryMenuType == menu)]
		// }

		function onClosePopup() {
			showPopup.value = null
		}

		function onCloseDeliveryMap() {
			showDeliveryMap.value = false
		}

		function orderStatus(status) {
			switch (status) {
				case 'OPEN':
					return t('orderStatus.PENDING')
				case 'ON_HOLD':
					return t('orderStatus.ON_HOLD')
				case 'DRAFTED':
					return t('orderStatus.PENDING_PAYMENT')
				case 'CANCELLED':
					return t('orderStatus.CANCELLED')
				case 'CLOSED':
					return t('orderStatus.CLOSED')
				case 'PACKED':
					return t('orderStatus.PACKED')
				case 'DELIVERED':
					return t('orderStatus.DELIVERED')
				case 'RECEIVED':
					return t('orderStatus.RECEIVED')
			}
		}

		async function submit(status) {
			switch (status) {
				case 'DRAFTED': {
					store.commit('setLoadingState', { loadingState: true })
					error.value = null
					const id = order.value.id
					await Browser.open({ url: `https://asia-east2-myjetmall.cloudfunctions.net/reconPay?id=${id}` })
					await Browser.addListener('browserFinished', () => {
						router.push(`/account/orders/${id}`)
					})
					error.value = null
					store.commit('setLoadingState', { loadingState: false })
					break
				}
				case 'DELIVERED': {
					showDeliveryMap.value = true
					break
				}
				case 'PACKED': {
					showDeliveryMap.value = true
					break
				}

				case 'SHOWQRCODE': {
					return (showPopup.value = {
						title: '掃描取貨條碼',
						active: true,
						type: 'qrCode',
					})
				}
				case 'CLOSED': {
					return (showPopup.value = {
						title: '評語',
						active: true,
						type: 'comment',
					})
				}
				default:
					return callSupport()
			}
		}
		async function showReceipt() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				await Browser.open({ url: `https://asia-east2-gox-dev-0511.cloudfunctions.net/api/sales/${order.value.id}/generateSaleReceipt` })
				// await Browser.open({ url: `https://asia-east2-myjetmall.cloudfunctions.net/getSaleOrderReceipt?id=${id.value}` })
				await Browser.addListener('browserFinished', () => {
					router.push(`/account/orders/${id.value}`)
				})
				error.value = null
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		function callSupport() {
			chatMessage.value = `${t('orderStatusActions.PENDING')} -  ${currentCart.value.id}`
			router.push('/account/chat')
		}

		return {
			currentLang,
			getVariantById,
			getProduct,
			moment,
			order,
			deliveryType,
			orderProducts,
			orderStatusActions,
			error,
			isLoading,
			showPopup,
			onClosePopup,
			orderStatus,
			submit,
			showDeliveryMap,
			onCloseDeliveryMap,
			center,
			showReceipt,
		}
	},
}
</script>
